<template>
    <div>
        <v-snackbar v-model="snackbar" :top="true" :color="color" :timeout="6000">
            <span v-if="snackbar_msg != null">{{ snackbar_msg }}</span>
            <v-btn dark text @click="snackbar = false">Close</v-btn>
        </v-snackbar>

        <v-row class="bradcrumb">
            <router-link :to="{ name: 'home' }" class="root"> Dashboard </router-link>
            <span class="child"> Book Bill Report</span>
        </v-row>
        <br />
        <v-card class="card">
            <v-card-title class="heading justify-center">Book Bill Report</v-card-title>
            <v-card-text>
                <v-row style="" class="pt-5">
                    <v-col cols="12" md="3" sm="3">
                      <label class="add-text">Purchase From Date</label>
                      <v-menu v-model="fromDateMenu" :close-on-content-click="false" transition="scale-transition" offset-y  full-width max-width="290px" min-width="290px">
                        <template v-slot:activator="{ on }">
                          <v-text-field class="text" v-model="fromDate"  placeholder="Purchase From Date" append-icon="event" persistent-hint dense readonly outlined v-on="on" :min="nowDate" :max="getEndDate"></v-text-field>
                        </template>
                        <v-date-picker v-model="fromDate" no-title @input="fromDateMenu = false"></v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col cols="12" md="3" sm="3">
                      <label class="add-text">Purchase To Date</label>
                      <v-menu v-model="toDateMenu" :close-on-content-click="false" transition="scale-transition" offset-y  full-width max-width="290px" min-width="290px">
                        <template v-slot:activator="{ on }">
                          <v-text-field class="text" v-model="toDate" placeholder="Purchase To Date" append-icon="event" persistent-hint dense readonly outlined v-on="on" :min="nowDate" :max="getEndDate"></v-text-field>
                        </template>
                        <v-date-picker v-model="toDate" no-title @input="toDateMenu = false"></v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col cols="12" md="1" sm="1">
                      <center><b>OR</b></center>
                    </v-col>
                    <v-col cols="12" md="5" sm="5">
                        <label class="add-text">Bill No.</label>
                        <v-text-field v-model="billno" dense outlined label="Bill No"></v-text-field>
                    </v-col>
                </v-row>
                <v-row class="pb-3" justify="center" align="center">
                    <v-btn class="m-2" color="info" @click="searchBill">Search</v-btn>
                </v-row>
                <v-data-table :headers="headers" :items="billList" class="elevation-1 nowrap" :search="search">
                    <template v-slot:top>
                        <v-toolbar flat color="white">
                            <v-text-field v-model="search" label="Search" append-icon="mdi-magnify" single-line hide-details></v-text-field>
                            <span style="width: 20px"></span>
                        </v-toolbar>
                    </template>
                </v-data-table>
            </v-card-text>
        </v-card>
        <v-overlay :value="overlay">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
    </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
import "@/assets/css/color.css"; 

export default {
  data: () => ({
    snackbar_msg: "",
    color: "",
    snackbar: false,
    search: "",
    overlay: false,

    billList: [],
    fromDateMenu: false,
    toDateMenu: false,
    fromDate: "",
    toDate: "",
    billno : "",
    nowDate: '0000-01-01',
    headers: [
      {
        text: "Sr no",
        align: "left",
        sortable: true,
        value: "srno",
      },
      {
        text: "Bill No.",
        align: "left",
        sortable: true,
        value: "billno",
      },  
      {
        text: "Date Of Entry",
        align: "left",
        sortable: true,
        value: "date_of_entry",
      },
      {
        text: "Title",
        align: "left",
        sortable: true,
        value: "title",
      },
      {
        text: "Accession No.",
        align: "left",
        sortable: true,
        value: "accession_number",
      },
      {
        text: "Source",
        align: "left",
        sortable: true,
        value: "vendor",
      },
      {
        text: "Author",
        align: "left",
        sortable: true,
        value: "author",
      },
      {
        text: "Publisher",
        align: "left",
        sortable: true,
        value: "publisher",
      },
      {
        text: "Bill Date",
        align: "left",
        sortable: true,
        value: "purchaseDate",
      },
      {
        text: "Cost",
        align: "left",
        sortable: true,
        value: "price",
      },
      {
        text: "Discount",
        align: "left",
        sortable: true,
        value: "discount",
      },
      {
        text: "Net-Cost",
        align: "left",
        sortable: true,
        value: "bookprice",
      },
      {
        text: "Department",
        align: "left",
        sortable: true,
        value: "department",
      },
    ],

  }),
  computed: {
    getEndDate() {
        var endDate = new Date();
        return endDate.toISOString().slice(0,10)
    },
  },

  mounted() {
    this.fromDate = moment(String(new Date())).format("YYYY-MM-DD");
    this.toDate = moment(String(new Date())).format("YYYY-MM-DD");
  },

  methods: {
    searchBill() {
      var params = {
        fromDate:this.fromDate,
        toDate : this.toDate,
        billno : this.billno,
      };
      this.overlay = true;
      axios
        .post("/Librarian/getBillData",params)
        .then((res) => {
          if (res.data.msg == "200") {
            this.overlay = false;
            this.billList = res.data.billList;
            if(this.billList.length == 0){
              this.showSnackbar("#b71c1c", "Data Not Found !!");
            }
          }
        }).catch((error) => {
          this.showSnackbar("#b71c1c", "Something went wrong..."); 
          window.console.log(error);
        }).finally(() => {
        });
    },

    showSnackbar(clr, msg) {
      this.snackbar = true;
      this.color = clr;
      this.snackbar_msg = msg;
    },

    exceltable() {    
        $("#exceltable").table2excel({  
        name: "Worksheet Name",
        filename: "Bill_Report",
        fileext: ".xls"
        });        
    },
  },
};
</script>
<style scoped>
.v-text-field {
  padding-top: 0px;
  margin-top: 0px;
}
.v-icon-size {
  font-size: 20px;
}
.edit-avatar {
  transition: 0.3s;
  border: 1px solid #b0bec5;
}
.edit-avatar:hover {
  background-color: #b0bec5;
}
.edit-v-icon {
  padding-left: 6px;
  font-size: 15px;
}
.edit-v-icon:hover {
  color: white;
}
.fields {
  padding: 0px !important;
}
.isdonated {
  margin-top: 15px;
  padding-left: 15px !important;
}
.check {
  color: green;
  font-size: 20px;
  padding-right: 10px;
}
.cross {
  color: red;
  font-size: 20px;
  padding-right: 5px;
}
.col-sm-12.col-md-4.col-lg-4.col-12 {
    padding-top: 0px;
    padding-bottom: 0px;
}

</style>